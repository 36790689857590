<template>
  <div class="content main_user_dashboard_wrapper">
    <!-- <card> -->
    <!--//* Start Timeline Cards  -->
    <div class="main_timeline_wrapper ">
      <!-- Start Steps  -->
      <div class="steps_container">
        <div
          class="one_step_wrapper "
          :class="{ first_step: card_status.lci == 1 }"
        >
          <span class="step">
            <i class="fa fa-check"></i>
          </span>
        </div>
        <div
          class="one_step_wrapper"
          :class="{ first_step: card_status.profile == 1 }"
        >
          <span class="step">
            <i class="fa fa-check"></i>
          </span>
        </div>
        <div
          class="one_step_wrapper"
          :class="{ first_step: card_status.pattern == 1 }"
        >
          <span class="step">
            <i class="fa fa-check"></i>
          </span>
        </div>
        <div
          class="one_step_wrapper"
          :class="{ first_step: card_status.contact == 1 }"
        >
          <span class="step">
            <i class="fa fa-check"></i>
          </span>
        </div>
        <div
          class="one_step_wrapper"
          :class="{ first_step: card_status.group_team == 1 }"
        >
          <span class="step">
            <i class="fa fa-check"></i>
          </span>
        </div>
      </div>
      <!-- End Steps  -->
      <!-- Start Cards  -->
      <div class="all_steps_cards_container">
        <div class="every_card_step">
          <router-link class="router_cards" to="/edit-questionnaire">
            <h4>{{ localization("LCI") }}</h4>
            <p>
              {{ localization("LCI Text") }}
            </p>
          </router-link>
        </div>
        <div class="every_card_step">
          <router-link class="router_cards" to="/profile">
            <h4>{{ localization("Personal Learning Profile") }}</h4>
            <p>
              {{ localization("Personal Learning Text") }}
            </p>
          </router-link>
        </div>
        <div class="every_card_step">
          <router-link class="router_cards" to="/tasks">
            <h4>{{ localization("Use Your Learning Patterns") }}</h4>
            <p>
              {{ localization("Learning Patterns Text") }}
            </p>
          </router-link>
        </div>
        <div class="every_card_step">
          <router-link class="router_cards" to="/contacts">
            <h4>{{ localization("Contacts") }}</h4>
            <p>
              {{ localization("Contacts Text") }}
            </p>
          </router-link>
        </div>
        <div class="every_card_step">
          <router-link class="router_cards" to="/teams">
            <h4>{{ localization("Groups/Teams") }}</h4>
            <p>
              {{ localization("Groups/Teams Text") }}
            </p>
          </router-link>
        </div>
      </div>
      <!-- End Cards  -->
    </div>
    <!--//* End Timeline Cards  -->
    <!-- </card> -->

    <!-- //? test  -->
    <div class="main_user_dashboard_instructions">
      <h5 class="titles" :class="{ is_rtl: isRtl() }">
        <i class="nc-icon nc-ruler-pencil"></i>
        <span>{{ localization("Description") }}</span>
      </h5>

      <div
        class="flow_instructions"
        :class="{ is_rtl: isRtl() }"
        v-if="flowInstructions"
        v-html="flowInstructions"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDashboard",
  data() {
    return {
      cards_steps: [
        {
          id: 0,
          title: "LCI",
          link: "/edit-questionnaire",
          text:
            "Complete the Learning Connection Inventory and  discover how your mind helps you learn, complete tasks, and work with others!"
        },
        {
          id: 1,
          title: "Personal Learning Profile",
          link: "/profile",
          text:
            "Build your unique Personal Learning Profile to share with team members, instructors, even potential employer!."
        },
        {
          id: 2,
          title: "Use Your Learning Patterns",
          link: "/tasks",
          text:
            "Select the personal strategies you need to succeed in completing any learning, work, or team assignment!"
        },
        {
          id: 3,
          title: "Contacts",
          link: "/contacts",
          text:
            "Invite your classmates, friends, business and school contacts to share their LCI scores with you and build your opportunity to share strategies!"
        },
        {
          id: 4,
          title: "Groups/Teams",
          link: "/teams",
          text:
            "Use the PLC to help you build effective  teams where your teammates understand each other and commit to learn together so, when necessary, they can each perform alone!"
        }
      ]
    };
  },
  computed: {
    // userTask

    card_status() {
      return this.$store.getters["user/get_dashboard_cards"];
    },
    flowInstructions() {
      return this.$store.getters["user/user_dashboard_Instructions"]
        ? this.$store.getters["user/user_dashboard_Instructions"][0].body
        : "";
    }
  },
  mounted() {
    this.$store.dispatch("user/get_dashboard_card_status");
    this.$store.dispatch("user/user", this.auth().id);
  }
};
</script>

<style lang="scss" scoped>
.main_user_dashboard_wrapper {
  padding: 15px;
  .main_user_dashboard_instructions {
    background: #fff;
    padding: 15px;
    border: 1px solid #ccc;
  }
  .titles {
    color: #777;
    text-transform: capitalize;
    margin: 0 0 20px;
    font-size: 18px;
    &.is_rtl {
      display: flex;
      gap: 8px;
      flex-direction: row-reverse;
    }
  }
  .flow_instructions {
    font-size: 18px !important;
    p {
      font-size: 18px !important;
    }
    &.is_rtl {
      text-align: right;
    }
  }
  .user_dashboard {
    border-bottom: 1px solid #ccc;
    .every_card_step {
      background-color: #fff;
      padding: 25px 10px 0;
      min-height: 200px;

      // text-align: center;
      border-radius: 10px;
      box-shadow: 0 5px 10px rgba(#000, 0.11);
      color: #777;
      transition: 0.3s;
      margin-bottom: 35px;
      &:hover {
        background-color: #11bfe3;
        color: #fff;
      }
      h4 {
        margin: 0 0 15px;
        text-transform: capitalize;
      }
      p {
        margin: 0;
        line-height: 1.7;
      }
    }
  }
  .main_timeline_wrapper {
    // background-color: #777;
    margin-bottom: 50px;
    // padding: 40px 10px;
    .steps_container {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-bottom: 15px;
      .one_step_wrapper {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        position: relative;
        &::after {
          // background-color: #e3e3e3;
          background-color: #fff;
          position: absolute;
          content: "";
          left: 0;
          top: 50%;
          width: 100%;
          height: 4px;
        }
        &.first_step {
          &::after {
            background-color: #11bfe3;
            background-color: #e5e5e5;
          }
          .step {
            background-color: #11bfe3;
            color: #fff;
          }
        }
        .step {
          position: relative;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: #fff;
          box-shadow: 0 5px 10px rgba(#000, 0.11);
          z-index: 2;
          color: #bbb;
          text-align: center;
          line-height: 30px;
          display: block;

          &::before {
            position: absolute;
            content: "";
            left: 50%;
            transform: translate(-50%, -50%);
            bottom: -30px;
            border-width: 15px;
            border-color: transparent transparent #fff transparent;
            border-style: solid;
          }
        }
      }
    }
    .all_steps_cards_container {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      .every_card_step {
        background-color: #fff;

        width: 20%;
        text-align: center;
        border-radius: 5px;
        box-shadow: 0 5px 10px rgba(#000, 0.11);
        transition: 0.3s;
        // border: 1px solid transparent;

        .router_cards {
          color: #777;
          transition: 0.5s;
          padding: 25px 10px 10px;
          height: 100%;
          min-height: 200px;

          display: block;
          cursor: pointer;
          h4 {
            margin: 0 0 15px;
            text-transform: capitalize;
            transition: 0.5s;
          }
          p {
            margin: 0;
            line-height: 1.7;
          }
          &:hover {
            h4 {
              color: #23ccef;
              // transform: translateX(4px);

              transform: translateY(-5px);
            }
            // border-color: #11bfe3;
            // color: #fff;
          }
        }
      }
    }
  }
  .all_texts_description {
    margin-top: 40px;
    p {
      margin: 0;
      line-height: 1.7;
      color: #777;
      font-size: 17px;
      margin-bottom: 2rem;
    }
  }
}
// @import "bourbon";

.wrapper {
  background: #e5e5e5;
  width: 90%;
  margin: 0 auto;
}

p {
  margin: 0;
}

.horizontal-timeline-container {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 12em;

  &::before {
    /* this is the horizontal line */
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background: red;
  }
}

.timeline-block {
  // @include clearfix;
  position: relative;
  flex: 1;
}

.timeline-step-counter {
  background: red;
  border-radius: 50%;
  top: 50%;
  line-height: 50px;
  margin-top: -25px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  height: 50px;
  width: 50px;
  text-align: center;
  color: white;
}

.timeline-content {
  background: white;
  border-radius: 5px;
  box-shadow: 0 2px 0 darken(#e5e5e5, 5%);
  padding: 1em;
  position: absolute;
  width: 100%;
  text-align: center;
  // min-height: 200px;

  .timeline-block:nth-child(odd) & {
    top: -25px;
  }

  .timeline-block:nth-child(even) & {
    bottom: -25px;

    &::before {
      left: 50%;
      margin-left: -7px;
      top: -7px;
      margin-top: -7px;
      border-color: transparent;
      border-bottom-color: white;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -7px;
    top: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-top: 7px solid white;
  }
}
</style>
